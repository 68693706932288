import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import InfoAndError from "./infoAndError.component"
import Label from "./label.component"
import { colors, condition } from "../../modules/structure/theme"
import { Helper } from "./helper.component"
import { StyledField } from "./styledField.component"
import breakpoint from "styled-components-breakpoint"
import { useFormikContext } from "formik"

const SquareRadio = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 17px;
  border: 2px solid ${colors(`selected`, `valid`, `grey.g500`)};
  background-color: ${colors(`selected`, `grey.500`)};
  font-weight: ${props => props.selected && 900};
  width: 100%;
  height: 100%;

  &:hover {
    border-color: ${colors(`valid`)};
    font-weight: 900;
    background-color: ${colors(`grey.g500`)};
  }

  &:focus {
    outline: none;
  }
`

const LabelRadio = styled.label`
  ${props => props.squareRadio && SquareRadio};
  cursor: pointer;
`

const InputRadio = styled.input`
  cursor: pointer;
  display: ${props => props.squareRadio && `none`};
  margin-right: 15px;
`

const RadioWrapper = styled.div`
  flex: ${props => props.squareRadio && 1};
  display: block;
  margin-bottom: 10px;

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: ${props => (props.squareRadio || props.verticalRadio ? `0px` : `10px`)};
  }

  ${breakpoint(`small`)`
    margin-left: ${condition(`verticalRadio`, `0`, `10px`)};
    margin-right: 10px;
    margin-bottom: 0;
    margin-top: 10px;
  `}

  &:first-of-type {
    margin-left: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex-direction: ${props => (props.verticalRadio || props.squareRadio ? `column` : `row`)};

  ${breakpoint(`small`)`
    flex-direction: ${props => (props.verticalRadio ? `column` : `row`)};
    margin-top: ${props => (props.label ? `10px` : `0`)};
  `};
`

export default function RadioGroup ({
  label,
  help = ``,
  field,
  items,
  renderInfo,
  squareRadio = false,
  verticalRadio = false,
  reversedTheme = false,
}) {
  const { name } = field
  // https://github.com/formium/formik/issues/2044
  const selectedValue = field.value === `true` ? true : field.value === `false` ? false : field.value
  const { setFieldValue, setFieldTouched } = useFormikContext()

  return (
    <StyledField role="radiogroup" data-testid={`form_field_${name}`}>
      {label && (
        <Label display="inline" reversedTheme={reversedTheme}>
          {label}
        </Label>
      )}
      <Helper help={help} />
      <Wrapper squareRadio={squareRadio} verticalRadio={verticalRadio} label={label}>
        {items.map(({ label: itemLabel, value }) => (
          <RadioWrapper key={value} squareRadio={squareRadio} verticalRadio={verticalRadio}>
            <InputRadio
              type="radio"
              data-testid={`form_value_${value}`}
              id={`${name}-${value}`}
              name={name}
              value={value}
              checked={value === selectedValue}
              squareRadio={squareRadio}
              onChange={() => {
                setFieldValue(name, value)
                setFieldTouched(name, true)
              }}
              onClick={() => {
                setFieldValue(name, value)
                setFieldTouched(name, true)
              }}
            />

            <LabelRadio
              squareRadio={squareRadio}
              htmlFor={`${name}-${value}`}
              selected={value === selectedValue}
              tabIndex="-1"
              role="radio"
              aria-checked={value === selectedValue}
            >
              {itemLabel}
            </LabelRadio>
          </RadioWrapper>
        ))}
      </Wrapper>
      <InfoAndError field={field} renderInfo={renderInfo} />
    </StyledField>
  )
}

RadioGroup.propTypes = {
  label: PropTypes.node,
  help: PropTypes.node,
  squareRadio: PropTypes.bool,
  verticalRadio: PropTypes.bool,
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    }),
  ).isRequired,
  reversedTheme: PropTypes.bool,
}
