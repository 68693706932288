import { createCaptchaHeader, fetchSite } from "../../utils/fetch"

export function postUnsubscribeMail (prospectId, offerCode, postalCode, reason, captchaToken) {
  return fetchSite(`/unsubscribe/mail`, {
    method: `PUT`,
    headers: createCaptchaHeader(captchaToken),
    body: JSON.stringify({
      prospectId,
      offerCode,
      postalCode,
      reason,
    }),
  })
}
