import React, { useEffect, useState, useRef, useMemo } from "react"
import PropType from "prop-types"
import { ReCAPTCHA } from "react-google-recaptcha"
import { useThirdPartiesContext } from "../../../modules/thirdParties/thirdParties.provider"
import { Error } from "../infoAndError.component"
import { useTranslation, Trans } from "../../../modules/translation/translate.component"
import { usePageContext } from "../../../modules/navigation/pageContext.provider"

export function useCaptcha () {
  const captchaRef = useRef()

  return useMemo(() => {
    return {
      name: `captcha`,
      Component: Captcha,
      props: { captchaRef },
      isReady: true,
      onSubmit: async values => {
        const token = await captchaRef.current.executeAsync()

        return {
          ...values,
          captchaToken: token,
        }
      },
    }
  }, [captchaRef])
}

export default function Captcha ({ captchaRef }) {
  const { t } = useTranslation()
  const { load, isLoaded } = useThirdPartiesContext()
  const { lang } = usePageContext()
  const [error, setError] = useState(false)

  useEffect(() => {
    load(`captcha`)
  }, [])

  return (
    isLoaded(`captcha`) && (
      <>
        <ReCAPTCHA
          hl={lang}
          ref={captchaRef}
          sitekey={APP_CONFIG.captcha.token}
          onErrored={() => setError(true)}
          grecaptcha={window.grecaptcha}
          size="invisible"
          id="captcha"
          badge="bottomleft"
        />
        {error && (
          <Error>
            <Trans
              t={t}
              i18nKey="common:generic.form_submit_captcha_error_validation"
              values={{ auto: `automatique ` }}
            />
          </Error>
        )}
      </>
    )
  )
}

Captcha.propTypes = {
  captchaRef: PropType.object.isRequired,
}
