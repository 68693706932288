import React, { useState } from "react"
import styled from "styled-components"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import InputText from "../../components/form/inputText.component"
import RadioGroup from "../../components/form/radioGroup.component"
import { Button } from "../structure/theme/button.component"
import { compose, enumeration, required, simplePostalCode } from "../../utils/validate"
import { Bold } from "../structure/theme/styles.component"
import { postUnsubscribeMail } from "./unsubscribe.api"
import { Error } from "../../components/form/infoAndError.component"
import { SuccessMessage } from "../information/successMessage.component"
import { Helper } from "../../components/form/helper.component"
import PhoneCta from "../contact/phoneCta.component"
import { useTranslation } from "../translation/translate.component"
import usePhoneCostNumber from "../contact/phoneCostNumber.hook"
import { useCaptcha } from "../../components/form/plugins/captcha.component"
import { useWebsiteConfiguration } from "../website/useWebsiteConfiguration.hook"
import Block from "../../components/block/block.component"

const TextContainer = styled.div`
  text-align: center;
`

const Submit = styled(Button)`
  margin: 0 auto;
`

const Bottom = styled.div`
  margin: 20px auto 0;
  max-width: 700px;
`

const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
  border: 1px solid black;
  padding: 25px;
`

const DoubleFieldLabel = styled.div`
  margin: 10px 0;
`

const InlineFields = styled.div`
  display: flex;
  align-items: baseline;
`

const Separator = styled.div`
  margin-bottom: 34px;
  flex: auto;
  text-align: center;
`

const SuccessUnsubscribe = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const REASON_WRONG_ADDRESS = `7`
const REASON_TOO_MUCH_MAIL = `5`
const REASON_NON_PROSPECTIVE = `8`

const formInitialState = {
  prospectId: ``,
  offerCode: ``,
  postalCode: ``,
  reason: ``,
}

function validate (values) {
  return {
    prospectId: required(values.prospectId),
    offerCode: required(values.offerCode),
    postalCode: compose(required, simplePostalCode)(values.postalCode),
    reason: compose(
      required,
      enumeration([REASON_WRONG_ADDRESS, REASON_TOO_MUCH_MAIL, REASON_NON_PROSPECTIVE]),
    )(values.reason),
  }
}

export default function StopMailingPage () {
  const { t } = useTranslation()
  const [errorForm, setErrorForm] = useState(``)
  const [successForm, setSuccessForm] = useState(false)
  const [btnActive, setBtnActive] = useState(true)
  const { salesPhone } = useWebsiteConfiguration()
  const phoneCostNumber = usePhoneCostNumber()
  const captcha = useCaptcha()

  const onSubmit = ({ captchaToken, ...values }) => {
    setBtnActive(false)

    return postUnsubscribeMail(values.prospectId, values.offerCode, values.postalCode, values.reason, captchaToken)
  }

  const onSubmitSuccess = () => setSuccessForm(true)

  const onSubmitFail = (values, actions, error) => {
    const errorStatus = error.response?.status ?? 0

    let message = `Une erreur est survenue lors de l'envoi du formulaire.`

    if (/400|404/.test(errorStatus)) {
      message = `Les informations renseignées ne correspondent à aucune adresse postale. Veuillez vérifier votre saisie.`
    }

    setErrorForm(message)
    setBtnActive(true)
  }

  return (
    <Block blockTitle={t(`common:unsuscribe.section_stop_mailing_primary`)} blockTitleSeo="h1">
      {salesPhone && (
        <TextContainer>
          <br />
          {t(`common:unsuscribe.section_stop_mailing_text`)}
          {` `}
          <Bold>
            <PhoneCta>{salesPhone}</PhoneCta>
          </Bold>
          {phoneCostNumber && ` (${phoneCostNumber}).`}
        </TextContainer>
      )}

      <br />

      {successForm && (
        <SuccessUnsubscribe>
          <SuccessMessage data-testid="stop_mailing_success">
            {t(`common:unsuscribe.section_stop_mailing_success`)}
          </SuccessMessage>
        </SuccessUnsubscribe>
      )}

      {!successForm && (
        <>
          <FormWrapper>
            <Form
              name="stop_mailing"
              captcha={captcha}
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitFail={onSubmitFail}
              initialValues={formInitialState}
              validate={validate}
            >
              <Bold>{t(`common:unsuscribe.form_stop_mailing_text`)}</Bold>

              <DoubleFieldLabel>
                {t(`common:unsuscribe.form_stop_mailing_reference`) + `* :`}
                <Helper help={t(`common:unsuscribe.form_stop_mailing_helper`)} />
              </DoubleFieldLabel>

              <InlineFields>
                <Field
                  component={InputText}
                  type="text"
                  name="offerCode"
                  label=""
                  placeholder={t(`common:unsuscribe.form_stop_mailing_offer_code_placeholder`)}
                />

                <Separator>&nbsp;-&nbsp;</Separator>

                <Field
                  component={InputText}
                  type="text"
                  name="prospectId"
                  placeholder={t(`common:unsuscribe.form_stop_mailing_prospect_id_placeholder`)}
                />
              </InlineFields>

              <Field
                component={InputText}
                type="text"
                name="postalCode"
                label={t(`common:unsuscribe.form_stop_mailing_postal_code`) + `* :`}
                placeholder="Ex: 69007"
              />

              <Field
                component={RadioGroup}
                name="reason"
                label={<Bold>{t(`common:unsuscribe.form_stop_mailing_reason`) + `*`}</Bold>}
                verticalRadio
                items={[
                  {
                    label: t(`common:unsuscribe.form_stop_mailing_reason1`),
                    value: REASON_WRONG_ADDRESS,
                  },
                  {
                    label: t(`common:unsuscribe.form_stop_mailing_reason2`),
                    value: REASON_TOO_MUCH_MAIL,
                  },
                  {
                    label: t(`common:unsuscribe.form_stop_mailing_reason3`),
                    value: REASON_NON_PROSPECTIVE,
                  },
                ]}
              />

              {errorForm && <Error data-testid="stop_mailing_error">{errorForm}</Error>}

              <Submit data-testid="stop_mailing_form_submit" disabled={!btnActive} type="submit">
                {t(`common:generic.form_continue_button`)}
              </Submit>
            </Form>
          </FormWrapper>

          <Bottom>
            {t(`common:generic.form_required_mention`)}
            <div>
              Les données collectées sont nécessaires à HomeServe et à ses partenaires pour la gestion de nos relations
              commerciales et pourront le cas échéant être utilisées à des fins de prospection selon les règles
              applicables. Conformément aux dispositions de la loi "Informatique et Libertés" du 6 janvier 1978, vous
              disposez d'un droit d'accès, de modification, d'opposition, de rectification et de suppression des données
              qui vous concernent, par mail auprès de webmestre@homeserve.fr ou par courrier auprès de HomeServe -
              Service Client - TSA 8211 - 69303 Lyon Cedex 07.
            </div>
          </Bottom>
        </>
      )}
    </Block>
  )
}
